import React from "react";
import { withRouter } from "react-router";
import { Row, Col, Button } from "antd";
import "./SalesDistribution.scss";
import "./../LandingPage/Home.scss";
import ctaImage from "../../assets/images/cta-img.png";
import salesHome from "../../assets/images/sales/sales-home-1.png";
import salesQuote from "../../assets/images/automation.png";
import sales2 from "../../assets/images/sales/sales-4.png";
import sales3 from "../../assets/images/sales/sales-6.png";
import cancellations from "../../assets/images/sales/cancellations.svg";
import proformaInvoice from "../../assets/images/sales/proforma-invoice.png";
import salesInvoice from "../../assets/images/sales/sales-invoice.png";


class SalesDistribution extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="sales-slider shape-15  py-5">
                    <div className="container mx-auto py-4">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Dynamic cloud-based Sales & Distribution Management</h3>
                                    <p className="sales-section-subtitle">bizfinway™ tracks your sales in real time, and generates intuitive graphs and charts to help you visualize where you stand as a company. bizfinway™ is mobile-friendly, so you can access all essential sales breakdowns, anywhere, anytime.</p>
                                    <Button type="secondary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={salesHome} alt="" className="w-100 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-11  py-5">
                    <div className="container mx-auto py-5">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-subtitle">The best cloud ERP with a customized sales approach</h2>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-email-document-100.png" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Inquiry and Quotation Management</h6>
                                        <p className="text-muted mb-0">Create Quotations and send them to customers right away</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-receipt-and-change-100.png" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Customer Specific Pricing</h6>
                                        <p className="text-muted mb-0">Send quotations to customers with customized pricing for each customer</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-get-cash-100.png" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Credit Control</h6>
                                        <p className="text-muted mb-0">Customer credit gives a lot of control and customer engagement</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-small-business-100.png" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Sales Order Costing</h6>
                                        <p className="text-muted mb-0">Create sales orders with customized payterms</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-supply-chain-100.png" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Stock Availability check </h6>
                                        <p className="text-muted mb-0">Check the product availability while creating sales orders, quotaions and invoices</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-partially-shipped-100.png" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Order Tracking and Booking</h6>
                                        <p className="text-muted mb-0">Track customer orders and delivery status</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className=" shape-10 py-5">
                    <div className=" container mx-auto ">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-title">Manage your sales efficiently </h2>
                                <p>One cloud solution to manage sales form anywhere in the world</p>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-11 py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content shape-9">
                                    <h3 className="section-title">Send sales quotations to your customers</h3>
                                    <p>An automated process, from sending an estimate/quotation to delivering materials and invoicing, all with a click of a button.Manage inventory efficiently with auto-updated stock-on-hand after each sale.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={salesQuote} className="w-100 img-responsive pl-lg-3" alt="Sales Quotation" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Create Sales orders internally or integrate with other existing systems</h3>
                                    <p>bizfinway™ ERP is one of the best solutions to create and manage sales orders, alongside approvals.view these documentsat a glance, with ease</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={sales2} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Track your outbound delivery orders with our shipping management system</h3>
                                    <p>When a new sales order gets processed, the system automatically prompts the stock, finance, and shipping departments required actions.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={sales3} className="w-100 img-responsive pl-lg-3" alt="Delivery Outbound" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Generate sales invoices and email payment links </h3>
                                    <p>Convert sales quotations, sales orders, outBound delivery challans to sales invoices. Manage all your customer invoices in one place.Close sales documents with customer credit balances</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={salesInvoice} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className=" py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Get payment beforehand through proforma invoices</h3>
                                    <p>Request payment from the committed buyer for goods or services. Send emails to customers about the description of the goods, the total payable amount and other details about the transaction.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={proformaInvoice} className="w-100 img-responsive pl-lg-3" alt="Delivery Outbound" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Manage returns & cancellations from refunds to document closing</h3>
                                    <p>Create return orders that route items on the original sales orders back into your inventory, and categorized orders as returns or cancellations.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={cancellations} className="w-100 img-responsive pr-lg-3" alt="cancellations" />
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="call-to-action shape-15">
                    <div className=" container mx-auto py-5">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents  shape-21" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Give your business a real boost now!</h3>
                                    <p className="section-subtitle">To accomplish great things, we must not only act, but also dream; not only plan, but also believe.</p>
                                    <Button type="primary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={ctaImage} alt="" className="w-70 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>

            </React.Fragment >
        );
    }
}
export default withRouter(SalesDistribution);
