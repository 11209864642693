import React from "react";
import { withRouter } from "react-router";
import { Row, Col, Button, Card } from "antd";
import "./OurTechnologies.scss";

import ourTech1 from "./../../assets/images/our-tech-1.png";
import angular from "./../../assets/images/angular.png";
/* import csharp from "./../../assets/images/technologies/csharp.png"; */
import android from "./../../assets/images/technologies/android.png";
import reactJs from "./../../assets/images/technologies/react-js.png";
import azure from "./../../assets/images/technologies/azure.png";
import htmL from "./../../assets/images/technologies/html.png";
import css3 from "./../../assets/images/technologies/css3.png";
import netDot from "./../../assets/images/technologies/net.png";
import python from "./../../assets/images/technologies/python.png";
import redux from "./../../assets/images/technologies/redux.png";
import sql from "./../../assets/images/technologies/sql.png";
import nodeJS from "./../../assets/images/technologies/node-js.png";

import ctaImage from "../../assets/images/cta-img.png";

const { Meta } = Card;
class OurTechnologies extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="bg-technologies-gradiant py-5">
                    <div className="container mx-auto py-2">
                        <Row className="py-4" style={{justifyContent: 'center'}}>
                            <h1 className="text-center text-white">Empowering clients <br></br>with latest technologies to serve thier customers better</h1>
                        </Row>
                        <Row className="our-technologies-keys">
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }}>
                                <Card style={{ width: 300, marginTop: 16 }} className="shape-1 text-white">
                                    <Meta title="Cloud Infrastructure" description="We deploy our solutions to reliable cloud servers"/>
                                </Card>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }}>
                                <Card style={{ width: 300, marginTop: 16 }} className="shape-2-1">
                                    <Meta title="Robotic Process Automation" description="Included for reccuring works to save time"/>
                                </Card>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }}>
                                <Card style={{ width: 300, marginTop: 16 }} className="shape-2">
                                    <Meta title="Predictive Analytics" description="Deployed ML models based on business data"/>
                                </Card>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span:6, order: 1 }}>
                                <Card style={{ width: 300, marginTop: 16 }} className="shape-4">
                                    <Meta title="Workflow Automation"  description="Automate various tasks parallelly and seamlessly"/>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-10 py-2">
                    <div className=" container mx-auto ">
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-12 py-5">
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={ourTech1} className="w-100 img-responsive pr-lg-3" alt="technologies" />
                            </Col>
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">We adapt latest technologies with time</h3>  
                                    <p>With cloud as Infrastructure we have a vast opportunity to deploy different technology implementations as microservices to integrate with our ERP, our technology teams are always inline with latest trends and solutions</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section> 
                <section className="shape-10 py-2">
                    <div className="what-we-do container mx-auto  py-5">
                            <Row align="center" gutter={[5, 10]} className="text-left">
                                <Col className="pr-lg-4" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <h2>Our Tech Stack</h2>
                                <p>Our Eco-System gives us an opportunity to integrate any possible solution that fits into an ERP solution based on customer needs </p>
                                <p>With a modular approach its more comfortable to integrate any third party API's with our API's , thus providing high flexibility </p>
                                
                                <Button type="secondary">Leran More..</Button>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        <Row align="center" gutter={[5, 5]}>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6}}>
                                            
                                            <div className="text-bold our-works">
                                                <img src={angular} className="img-responsive pr-lg-3" alt="technologies" width="100"/>
                                            </div>
                                            </Col>
                                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span:6 }} lg={{ span: 6 }}>
                                            <div className="our-works">
                                            <img src={netDot} className="img-responsive pr-lg-3" alt="technologies" width="100"/>
                                            </div>
                                            
                                            </Col>
                                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <div className="our-works">
                                            <img src={reactJs} className="img-responsive pr-lg-3" alt="technologies" width="100"/>
                                            </div>
                                            
                                            </Col>
                                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6}} lg={{ span: 6 }}>
                                            <div className="our-works">
                                            <img src={nodeJS} className="img-responsive pr-lg-3" alt="technologies" width="100"/>
                                            </div>
                                            
                                            </Col>
                                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6}} lg={{ span: 6 }}>
                                            <div className="our-works">
                                            <img src={htmL} className="img-responsive pr-lg-3" alt="technologies" width="100"/>
                                            </div>
                                            </Col>
                                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6}} lg={{ span: 6}}>
                                            <div className="our-works">
                                            <img src={css3} className="img-responsive pr-lg-3" alt="technologies" width="100"/>
                                            </div>
                                            </Col>
                                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6}} lg={{ span: 6}}>
                                            <img src={sql} className="img-responsive pr-lg-3" alt="technologies" width="100"/>
                                            </Col>
                                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6}} lg={{ span: 6}}>
                                            <div className="our-works">
                                            <img src={azure} className="img-responsive pr-lg-3" alt="technologies" width="100"/>
                                            </div>
                                            </Col>
                                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6}} lg={{ span: 6}}>
                                            <div className="our-works">
                                            <img src={redux} className="img-responsive pr-lg-3" alt="technologies" width="100"/>
                                            </div>
                                            </Col>
                                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6}} lg={{ span: 8}}>
                                            <div className="our-works">
                                            <img src={python} className="img-responsive pr-lg-3" alt="technologies" width="100"/>
                                            </div>
                                            </Col>
                                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6}} lg={{ span: 6}}>
                                            <div className="our-works">
                                            <img src={android} className="img-responsive pr-lg-3" alt="technologies" width="100"/>
                                            </div>
                                            </Col>
                                        </Row>
                                </Col>
                            </Row>
                    </div>
                </section>  
                <section className="bg-success-gradiant py-5">
                    <div className="container mx-auto py-5">
                            <Row align="center" gutter={[5, 10]} className="text-left">
                                <h2 className="text-white section-title">Technology Integrated with business,you are at the right place.</h2>
                            </Row>
                    </div>
                </section>  
                <section className="call-to-action shape-2-1">
                    <div className=" container mx-auto py-5">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Give your Business <br />Real boost Now!</h3>
                                    <p className="section-subtitle">To accomplish great things, we must not only act, but also dream; not only plan, but also believe.</p>
                                    <Button type="primary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={ctaImage} alt="" className="w-70 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(OurTechnologies);

