import React from "react";
import { withRouter } from "react-router";
import { Row, Col, Button } from "antd";
import "./../LandingPage/Home.scss";
import "./HrAndPayroll.scss";
import ctaImage from "../../assets/images/cta-img.png";
import employeeMangement from "../../assets/images/payroll/employee-management.png";
import incomeTax from "../../assets/images/payroll/e-tax.png";
import productionData from "../../assets/images/payroll/production-data.png";
import wageCalculation from "../../assets/images/payroll/wage-calculation.png";


class HrAndPayroll extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="shape-1 py-5">
                    <div className="container mx-auto py-4">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title shape-1 mb-2">A powerful and complete Payroll and HR System</h3>
                                    <p className="sales-section-subtitle">Wisely made to help Companies automate their workflows</p>
                                    <Button type="secondary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="wrap">
                                    <div className="wrap-inner">
                                        <div className="container-box">

                                            <div className="box" id="b1">
                                                <div className="wall-1"></div>
                                                <div className="wall-2"></div>
                                                <div className="wall-3"></div>
                                                <div className="wall-4"> </div>
                                                <div className="wall-5">
                                                    <span className="box-logo">Salary</span>
                                                </div>
                                                <div className="wall-6">
                                                    <span className="box-logo">Payouts</span>
                                                </div>

                                                <p className="info">Manage Salary and process salary directly to bank account of employees</p>
                                            </div>

                                            <div className="box" id="b2">
                                                <div className="wall-1"></div>
                                                <div className="wall-2"></div>
                                                <div className="wall-3"></div>
                                                <div className="wall-4">

                                                </div>
                                                <div className="wall-5">
                                                    <span className="box-logo pers">Shift</span>
                                                </div>
                                                <div className="wall-6">
                                                    <span className="box-logo">Time Sheets</span>
                                                </div>
                                                <p className="info">Define Custom Attendance and shift rues and masters</p>
                                            </div>

                                            <div className="box" id="b3">
                                                <div className="wall-1"></div>
                                                <div className="wall-2"></div>
                                                <div className="wall-3"></div>
                                                <div className="wall-4">

                                                </div>
                                                <div className="wall-5">
                                                    <span className="box-logo">Employee</span>
                                                </div>
                                                <div className="wall-6">
                                                    <span className="box-logo">Portal</span>
                                                </div>

                                                <p className="info">Employee Portal for employees to submit attendance, apply leaves and manage expenses</p>
                                            </div>

                                            <div className="box" id="b4">
                                                <div className="wall-1"></div>
                                                <div className="wall-2"></div>
                                                <div className="wall-3"></div>
                                                <div className="wall-4"></div>
                                                <div className="wall-5">
                                                    <span className="box-logo">Job</span>
                                                </div>
                                                <div className="wall-6">
                                                    <span className="box-logo">Recruit</span>
                                                </div>
                                                <p className="info">Candidate application processing with approvals and onboarding</p>
                                            </div>

                                            <div className="box" id="b5">
                                                <div className="wall-1"></div>
                                                <div className="wall-2"></div>
                                                <div className="wall-3"></div>
                                                <div className="wall-4"></div>
                                                <div className="wall-5">
                                                    <span className="box-logo">Payouts</span>
                                                </div>
                                                <div className="wall-6">
                                                    <span className="box-logo">Payslips</span>
                                                </div>
                                                <p className="info">Auto generated reports to manage , monitor and analyse payouts</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-10  py-5">
                    <div className="container mx-auto py-5">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-title">Fast and reliable payroll processing with Industry standards</h2>
                                <p>Accurate, Feature perfect and most eligible Payroll system for SME's</p>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="plan [ h-card ]">
                                    <div className="inner">
                                        <h2 className="title">Employee Management</h2>
                                        <p className="info">Manage all employees and their personal and Professional Details</p>
                                        <ul className="features">
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Create Employees</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Relieving</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Equipment Submission</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="plan [ h-card ]">
                                    <div className="inner">
                                        <h2 className="title">Leave Management</h2>
                                        <p className="info">Define leave types and assign Leaves to employee</p>
                                        <ul className="features">
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Leave Master</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Assign Leaves</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Balance Entry</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Leave Application</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Leave Approval</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="plan [ h-card ]">
                                    <div className="inner">
                                        <h2 className="title">Shift and Attendance</h2>
                                        <p className="info">Define Shifts, Holidays , breaks and relaxation times and allocate and define attendance rules</p>
                                        <ul className="features">
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Shift Master</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Shift Allocation</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Time Rules</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Attendance Approval</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Bulk Procesing</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Breaks and Holidays</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Import and Export</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="plan [ h-card ]">
                                    <div className="inner">
                                        <h2 className="title">Salary and Payouts</h2>
                                        <p className="info">Define salary heads, taxable heads, recovery and settlements, payouts </p>
                                        <ul className="features">
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Salary Master</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Employee CTC</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Salary Increment</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Benefits - Deductions</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Bulk Salary Processing</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Salary Payouts</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Register</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Adjustment - Recovery</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span>Payslips and Reports</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-9  py-5">
                    <div className="container mx-auto py-5">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-subtitle">HR Management designed for small and medium business with domain level customization</h2>
                                <p>Simple, Reliable and hassle free Recruitment process flows</p>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-recruit100.png" alt="recruit" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Requisition to hire</h6>
                                        <p className="text-muted mb-0">Create Quotations and send it to customers right away</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-check-document-100.png" alt="approval" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Requisition Approval</h6>
                                        <p className="text-muted mb-0">Send quotations to customers with customized  for each customer</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-loud-speaker-100.png" alt="vacancy" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Vacancy Advertisement</h6>
                                        <p className="text-muted mb-0">Customer credit gives a lot of control and customer engagement</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-hard-working-100.png" alt="applicants" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Applicants and Application Tracking</h6>
                                        <p className="text-muted mb-0">Create sales orders with customized payterms</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-job-process-100.png" alt="recruitment-process"  width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Recruitment Process</h6>
                                        <p className="text-muted mb-0">Check the product availability while creating ales order, Quotaion, Invoices</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-new-job-100.png" alt="appointment" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Offer Release & Appointment</h6>
                                        <p className="text-muted mb-0">Track the orders placed by customers and delivery status of sales orders</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="endtoend-clouderp  shape-10 py-5">
                    <div className=" container mx-auto ">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-subtitle">A system packed with powerful Integrated HR and Payroll</h2>
                                <p></p>
                            </Col>
                        </Row>

                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-11 py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content shape-9">
                                    <h3>Comprehensive Employee details (Personnel Details, Company, Statutory, Bank)</h3>
                                    <p></p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={employeeMangement} className="w-100 img-responsive pl-lg-3" alt="Sales Quotation" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3>Robust Income tax processing as per latest IT rules & Regulations</h3>
                                    <p></p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={incomeTax} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3>Easy Interface for integration with Production Data of any ERP</h3>
                                    <p></p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={productionData} className="w-100 img-responsive pl-lg-3" alt="Delivery Outbound" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3>Wage/Incentive calculation with Piece rate processing</h3>
                                    <p></p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={wageCalculation} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="call-to-action shape-2-1">
                    <div className=" container mx-auto py-5">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Give your Business <br />Real boost Now!</h3>
                                    <p className="section-subtitle">To accomplish great things, we must not only act, but also dream; not only plan, but also believe.</p>
                                    <Button type="primary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={ctaImage} alt="" className="w-70 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(HrAndPayroll);
