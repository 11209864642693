import React from "react";
import { withRouter } from "react-router";
import { Row, Col, Button } from "antd";

import businessAnalytics from '../../../assets/images/home-end-to-end.png';
import customizationEverylevel from "../../../assets/images/customization-every-level.png"
import automation from "../../../assets/images/automation.png"
import multiCurrency from "../../../assets/images/home-multi-currency.svg"
import approvalProcess from "../../../assets/images/home/approved-1.png";
import analysisPeople from "../../../assets/images/home/analysis-people.png";
import multiDesign from "../../../assets/images/home/multi-dimention-design.png";
import multiLanguage from "../../../assets/images/home/multi-language.png";
import scalability from "../../../assets/images/home/seamless-scalability.png";
import workflowAutomation from "../../../assets/images/home/workflow-automation.png";
import importExport from "../../../assets/images/home/export-import.svg";

class EndtoendClouderp extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="endtoend-clouderp shape-10 py-5">
                    <div className=" container mx-auto ">
                        {/*  <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 class="section-title">End to End Cloud ERP Solutions</h2>
                            </Col>
                        </Row> */}

                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-1-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3>Business Analytics</h3>
                                    <p>bizfinway™ Dashboard Suite uses data visualization to display key performance indicators that highlight functional performance, and generate actionable insights.</p>
                                    <Button type="secondary">Learn More..</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={businessAnalytics} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>

                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3>Role Based Customization</h3>
                                    <p>bizfinway’s modular design allows for a high degree of flexibility and customization, based on roles and business domain.</p>
                                    <Button type="secondary">Explore</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={customizationEverylevel} className="w-100 img-responsive pl-lg-3" alt="Customization at every level" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-1-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3>Transaction Approval Process</h3>
                                    <p>With multi-level approval based on transactions, assign multiple users for various levels: purchase requisitions, sales order amendments, and approvals based on price range.</p>
                                    <Button type="secondary">Learn More..</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={approvalProcess} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>

                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-12 py-5">
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={multiCurrency} className="w-100 img-responsive pr-lg-3" alt="Automation" />
                            </Col>
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3>Multi-Currency Settings</h3>
                                    <p>bizfinway™ offers multi-curreny settings so you can do business around the world.Create currency-based quotations and invoices, with currency conversion rates</p>
                                    <Button type="secondary">Get Expert Opinion</Button>
                                </div>
                            </Col>
                        </Row>

                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-1-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3>Multi Dimensional Design</h3>
                                    <p>bizfinway™ design proves effective with built-in custom, transation-level standards</p>
                                    <Button type="secondary">Learn More..</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={multiDesign} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>

                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-9 py-5">
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={analysisPeople} className="w-100 img-responsive pr-lg-3" alt="Automation" />
                            </Col>
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3>Executive Dashboards</h3>
                                    <p>Visually stunning, role specific dashboards let you customize Key performance indicators, giving you an overview of your business in seconds.</p>
                                    <Button type="secondary">Get Expert Opinion</Button>
                                </div>
                            </Col>
                        </Row>

                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3>Painless Data Import and Export</h3>
                                    <p>For an ERP to function on a daily basis, it must to be able to process files in various formats. With bizfinway, enjoy near-effortless data importing and exporting, such as high priority documents and reports</p>
                                    <Button type="secondary">Explore</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={importExport} className="w-100 img-responsive pl-lg-3" alt="Customization at every level" />
                            </Col>
                        </Row>

                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-15 py-5">
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={multiLanguage} className="w-100 img-responsive pr-lg-3" alt="Automation" />
                            </Col>
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3>Multi-Language Settings and Support</h3>
                                    <p></p>
                                    <Button type="secondary">Get Expert Opinion</Button>
                                </div>
                            </Col>
                        </Row>

                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3>Seamless Scalability</h3>
                                    <p>No Storage restrictions: bizfinway™ scales your storage capacity to your needs, without data loss or data transfer delay.</p>
                                    <Button type="secondary">Explore</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={scalability} className="w-100 img-responsive pl-lg-3" alt="Customization at every level" />
                            </Col>
                        </Row>

                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-12 py-5">
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={workflowAutomation} className="w-100 img-responsive pr-lg-3" alt="Automation" />
                            </Col>
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3>Workflow Automation</h3>
                                    <p>Imagine how automation can improve your operational capacity with error-free processing across various transactions.</p>
                                    <Button type="secondary">Get Expert Opinion</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(EndtoendClouderp);
