import React from "react";
import { withRouter } from "react-router";
import "./FeaturesTab.scss";
import { Row, Col, Button } from "antd";
import salesDistributionImg from '../../../assets/images/sales-distribution.svg';

class SalesDistribution extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Row align="center" gutter={[12, 22]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8}}>
                        <img src={salesDistributionImg} className="w-100 img-responsive pr-lg-3" alt="Business. Made easy. bizfinway™" />
                    </Col>
                    <Col className="text-left" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} >
                        <h3>Sales & Distribution</h3>
                        <Row align="left" gutter={[12, 22]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} >
                                <div className="box-part text-left">
                                    <div className="text">
                                        <span>Manage multiple warehouses, branches and locations in real time.</span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="box-part text-left">
                                    <div className="text">
                                        <span>Stay in touch with customers and your market.</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row align="left" gutter={[12, 22]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} >
                                <div className="box-part text-left">
                                    <div className="text">
                                        <span>Manage orders, pricing, storages, payments, retuns and cancellations.</span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} >
                                <div className="box-part text-left">
                                    <div className="text">
                                        <span>Our Sales and Distribution dashboard lets you view all vital information in one screen, in real time.</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
export default withRouter(SalesDistribution);
