import React from 'react';
import { withRouter } from 'react-router';
import InputBox from '../../Components/InputBox/InputBox';
import { Row, Col, Tabs, Button } from 'antd';
import './SigninSignup.scss';

const { TabPane } = Tabs;

class SigninSignup extends React.Component {
    constructor() {
        super()
        this.state = {
            defaultActiveKey: [],
            mobileNumber: '',
            fullName: '',
            emailId: '',
            phoneNumber: '',
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    render() {
        return (
            <React.Fragment>
                <div className="signin-singup">
                    <Row gutter={[12, 20]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <img src={require('../../assets/images/jambotronwhy.png')} alt="Signin Singup" />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <div className="padding-30">
                                <h5 className="text-center text-dark text-bold">Welcome to bizfinway™</h5>
                                <h6 className="text-center">Get started in just 3 steps</h6>
                            </div>
                            {/*<Tabs defaultActiveKey="signin">
                                <TabPane tab="Sign in" key="signin">
                                    <p>We will send you a verification code to your phone number</p>
                                    <form>
                                        <div className="form-fields">
                                            <InputBox
                                                placeholder="Enter mobile number"
                                                id="mobileNumber"
                                                value={this.state.mobileNumber}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                            />
                                        </div>
                                        <div className="form-fields">
                                            <Button type="primary">Continue</Button>
                                        </div>
                                    </form>
                                    <Divider>or Continue Using</Divider>
                                    <div className="social-login">
                                        <Button type="secondary" className="google"><img src={require('../../assets/images/icons/google.svg')} alt="google signin" /> Google</Button>
                                        <Button type="secondary" className="facebook"><img src={require('../../assets/images/icons/facebook.svg')} alt="Facebook signin" />  Facebook</Button>
                                    </div>
                                </TabPane> */}
                            {/* signin tab ends */}

                            {/* <TabPane tab="Sign up" key="signup"> */}

                            <form>
                                <div className="form-fields">
                                    <InputBox
                                        placeholder="Enter Full Name"
                                        id="fullName"
                                        value={this.state.fullName}
                                        onChangeText={this.onChangeText}
                                        type="text"
                                    />
                                </div>
                                <div className="form-fields">
                                    <InputBox
                                        placeholder="Enter Work Email"
                                        id="emailId"
                                        value={this.state.emailId}
                                        onChangeText={this.onChangeText}
                                        type="email"
                                    />
                                </div>
                                <div className="form-fields">
                                    <InputBox
                                        placeholder="Enter Phone Number"
                                        id="phoneNumber"
                                        value={this.state.phoneNumber}
                                        onChangeText={this.onChangeText}
                                        type="text"
                                    />
                                </div>
                                <div className="form-fields">
                                    <Button type="primary">Continue</Button>
                                </div>
                            </form>
                            {/* </TabPane> */}
                            {/* signup tab ends */}
                            {/* </Tabs> */}
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(SigninSignup)