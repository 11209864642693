import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import Home from '../Screens/LandingPage/Home';
import FinancialAccounting from '../Screens/FinancialAccounting/FinancialAccounting';
import SalesDistribution from '../Screens/SalesDistribution/SalesDistribution';
import HrAndPayroll from '../Screens/HrAndPayroll/HrAndPayroll';
import PaymentIntegration from '../Screens/PaymentIntegration/PaymentIntegration';
import ProcurementInventory from '../Screens/ProcurementInventory/ProcurementInventory';
import Layout from '../Layout/Layout';
import Pricing from '../Screens/Pricing/Pricing';
import FixedAssets from '../Screens/FixedAssets/FixedAssets';
import WhyChooseUs from '../Screens/WhyChooseUs/WhyChooseUs';
import OurTechnologies from '../Screens/OurTechnologies/OurTechnologies';
import OpenBanking from '../Screens/OpenBanking/OpenBanking';
import QualityManagement from '../Screens/QualityManagement/QualityManagement';
import PointOfSale from '../Screens/PointOfSale/PointOfSale';
import OurCompany from '../Screens/OurCompany/OurCompany';
class InnerRoutes extends React.Component {
    render() {
        const {
            match: { url },
        } = this.props;
        return (
            <Layout {...this.props}>
                <Switch>
                    <Route exact path={`/`} component={Home} />
                    <Route exact path={`/financial-accounting`} component={FinancialAccounting} />
                    <Route exact path={`/sales-and-distribution`} component={SalesDistribution} />
                    <Route exact path={`/procurement-inventory`} component={ProcurementInventory} />
                    <Route exact path={`/hr-and-payroll`} component={HrAndPayroll} />
                    <Route exact path={`/fixed-assets`} component={FixedAssets} />
                    <Route exact path={`/payment-integration`} component={PaymentIntegration} />
                    <Route exact path={`/open-banking`} component={OpenBanking} />
                    <Route exact path={`/quality-management`} component={QualityManagement} />
                    <Route exact path={`/point-of-sale`} component={PointOfSale} />
                    <Route exact path={`/pricing`} component={Pricing} />
                    <Route exact path={`/why-choose-us`} component={WhyChooseUs} />
                    <Route exact path={`/our-technologies`} component={OurTechnologies} />
                    <Route exact path={`/our-company`} component={OurCompany} />
                </Switch>
            </Layout>
        );
    }
}

export default InnerRoutes;
