import React from "react";
import { withRouter } from "react-router";
import { Row, Col, Button, Card, Avatar } from "antd";

import "./OurCompany.scss"

import whyChooseUs1 from "./../../assets/images/why-choose-us-1.png";
import ctaImage from "../../assets/images/cta-img.png";

const { Meta } = Card;
class OurCompany extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="bg-success-gradiant py-5">
                    <div className="section-wsl container mx-auto py-2">
                        <Row align="center" className="text-center py-2">
                            <h3  className="section-title text-white">bizfinway™ is an ERP powered by Genems Systems</h3>
                        </Row>
                        <Row className="py-4" style={{justifyContent: 'center'}}>
                            <p className="text-center text-white">Our curiosity led us to create an ERP to fit every business.</p>
                        </Row>
                    </div>
                </section>
                <section className="shape-10 py-2">
                    <div className=" container mx-auto ">
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-12 py-5">
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={whyChooseUs1} className="w-100 img-responsive pr-lg-3" alt="Automation" />
                            </Col>
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Our vision to create</h3>  
                                    <p>bizfinway™ is deployment-platform agnostic, customers can consume it in Software-as-a-Service (SaaS) mode or choose to deploy on their private clouds (AWS, IBM, Azure or Google).</p>
                                </div>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className=" py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Get payment beforehand through proforma invoices</h3>
                                    <p>Request payment from the committed buyer for goods or services. Send emails to customers about the description of the goods, the total payable amount and other details about the transaction.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src="" className="w-100 img-responsive pl-lg-3" alt="Delivery Outbound" />
                            </Col>
                        </Row>
                    </div>
                </section> 
                <section className="bg-partner-gradiant py-5">
                    <div className="py-5">
                        <Row className="py-4" style={{justifyContent: 'center'}}>
                            <h2 className="text-center">Great ideas evolve with colloboration, communication and passion</h2>          
                        </Row>
                        <Row align="center" className="text-center py-2">
                        <p>With more than 8 clients, we're experts at enhancing business operations for clients in typical business domains.</p>     
                        </Row>                        
                    </div>
                </section>
                <section className="shape-10 py-2">
                    <div className="what-we-do container mx-auto  py-5">
                            <Row align="center" gutter={[5, 10]} className="text-left">
                                <Col className="pr-lg-4" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <h2>Optimal & Intelligent</h2>
                                <p>Integrated with latest technologies our ERP is designed with great architecture, and is a cloud based system.</p>
                                <p>All members of our core management team are experts in their respective fields, and have been working together for several years.</p>
                                <p>Our in-house IT and development team allows us to provide technology-enabled solutions that can make processes more efficient and effective.</p>
                                <Button type="secondary">Learn More..</Button>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Row align="center" gutter={[5, 5]}>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="text-bold our-works">Delivering Business Value</div>
                                    <p>We go the extra mile to ensure our software achievies your business goals.</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="our-works">Structured Approach</div>
                                    <p>An integrated approch to fit all divisions, and ensure data integrity</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="our-works">Low Investment Cost</div>
                                    <p>Get those investors right into your business with a cost effective IT systems</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="our-works">Adaptability</div>
                                    <p>Since bizfinway™ is flexible and design driven system can be adapted by all</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="our-works">Industry Expertise</div>
                                    <p>Our business analytics team has a combined 19+ years of experience in ERP domain</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="our-works">Quality Assured</div>
                                    <p>Thousands of hours testing in terms of functional and non-functional aspects</p>
                                    </Col>
                                </Row>
                                </Col>
                            </Row>
                    </div>
                </section>  
                <section className="bg-success-gradiant py-5">
                    <div className="container mx-auto py-5">
                            <Row align="center" gutter={[5, 10]} className="text-left">
                                <h2 className="text-white section-title">Your success is our goal, let's do it.</h2>
                            </Row>
                    </div>
                </section>  
                <section className="call-to-action shape-2-1">
                    <div className=" container mx-auto py-5">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Give your business a real boost Now!</h3>
                                    <p className="section-subtitle">To accomplish great things, we must not only act, but also dream; not only plan, but also believe.</p>
                                    <Button type="primary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={ctaImage} alt="" className="w-70 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(OurCompany);

