import React from "react";
import { withRouter } from "react-router";
import "./FeaturesTab.scss";
import bannerImg from '../../../assets/images/bannerimg.png';
import { AppleOutlined, AndroidOutlined } from '@ant-design/icons';
import { Row, Col, Tabs, Button } from "antd";
import salesDistributionimg from '../../../assets/images/Group_1.png';
import ceoDashboard from '../../../assets/images/Group_2.png';
import purchaseStore from '../../../assets/images/Group_3.png';
import financialAccounting from '../../../assets/images/Group_4.png';
import reportingSheets from '../../../assets/images/Group_5.png';
import customizationCounters from '../../../assets/images/Group_6.png';
import SalesDistribution from './SalesDistribution';

const { TabPane } = Tabs;

class FeaturesTab extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="short-features shape-15 py-3">
                    <div className=" container mx-auto pt-2 py-5">
                        <Row align="center" gutter={[12, 22]} className="text-center">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Tabs defaultActiveKey="1" centered>
                                    <TabPane
                                        tab={
                                            <span>
                                                <img src="https://images.impacttopline.com/itop/icons/itop-supply-chain-100.png" alt="Sales & Distribution" width="40" />
                                                <p>Distribution</p>
                                            </span>
                                        }
                                        key="1"
                                    >
                                        <SalesDistribution />
                                    </TabPane>
                                    <TabPane
                                        tab={
                                            <span>
                                                <img src="https://images.impacttopline.com/itop/icons/itop-color-switch-100.png" alt="CEO Dashboard" width="40" />
                                                <p>Role based Dashboards</p>
                                            </span>
                                        }
                                        key="2"
                                    >
                                        <SalesDistribution />
                                    </TabPane>
                                    <TabPane
                                        tab={
                                            <span>
                                                <img src="https://images.impacttopline.com/itop/icons/itop-fork-lift-100.png" alt="Purchase & Store" width="40" />
                                                <p>Materials</p>
                                            </span>
                                        }
                                        key="3"
                                    >
                                        <SalesDistribution />
                                    </TabPane>
                                    <TabPane
                                        tab={
                                            <span>
                                                <img src="https://images.impacttopline.com/itop/icons/itop-cash-100.png" alt="Financial Accounting" width="40" />
                                                <p>Accounting Books</p>
                                            </span>
                                        }
                                        key="4"
                                    >
                                        <SalesDistribution />
                                    </TabPane>
                                    <TabPane
                                        tab={
                                            <span>
                                                <img src="https://images.impacttopline.com/itop/icons/itop-report-file-100.png" alt="CEO Dashboard" width="40" />
                                                <p>Reporting & Sheets</p>
                                            </span>
                                        }
                                        key="5"
                                    >
                                        <SalesDistribution />
                                    </TabPane>
                                    <TabPane
                                        tab={
                                            <span>
                                                <img src="https://images.impacttopline.com/itop/icons/itop-game-controller-100.png" alt="CEO Dashboard" width="40" />
                                                <p>Customization</p>
                                            </span>
                                        }
                                        key="6"
                                    >
                                        <SalesDistribution />
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(FeaturesTab);
