import React from "react";
import { withRouter } from "react-router";
import { Row, Col, Button } from "antd";
import "./../LandingPage/Home.scss";
import "./PointOfSale.scss";

import ctaImage from "../../assets/images/cta-img.png";
import pos1 from "../../assets/images/quality/pos-1.svg";
import pos2 from "../../assets/images/quality/pos-2.svg";
import pos3 from "../../assets/images/quality/pos-3.svg";
import pos4 from "../../assets/images/quality/pos-4.svg";
import pos5 from "../../assets/images/quality/pos-5.svg";

import {
    CheckCircleOutlined,
    BellOutlined
} from '@ant-design/icons';
class PointOfSale extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="shape-2-1 py-5" style={{backgroundColor:'#002526'}}>
                    <div className="container shape-21 mx-auto py-3">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title text-white mb-2">Point of sale that works on any device</h3>
                                    <p className="sales-section-subtitle text-white">Your point of sale must be an all-in-one integrative solution that lets you run and scale your business with ease</p>
                                    <Button type="secondary" className="text-uppercase text-white">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={pos1} alt="point-of-sale" className="w-100 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-2-1 py-5">
                    <div className="container mx-auto py-5">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-subtitle">Go Digital with and sell Smarter, Faster & Better</h2>
                                <p>Integrate our bizfinway™ POS easily which is configurable according to the requirements, irrespective of the business type & size.  </p>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-skip-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">User-friendly</h6>
                                        <p className="text-muted mb-0">A smart interface that any retail company can use without difficulty.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-truck-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3"> Invoice Customisation</h6>
                                        <p className="text-muted mb-0"> Choose from a wide range of available pre-defined invoice template & design according with company Logo & brand identity</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-reporting-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Payment Options</h6>
                                        <p className="text-muted mb-0">Accept cards, cash, split payments and more on the go.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-22 py-2" style={{backgroundColor:'#05005f'}}>
                    <div className="container shape-12 mx-auto py-5">
                        <Row align="center" className="text-center pb-3">
                            <h2 className="text-white section-title">Sell Smarter, Faster & Better</h2>
                        </Row>
                        <Row align="center" className="text-center pb-3">
                            <p className="text-white">bizfinway™ POS supports your business whether you operate a standalone store or many points of sale across multiple locations. It offers flexibility to handle both single and multiple sales channels across your entire organization. This is mobile based and can work on Android as well as iOS platforms, thus it provides you mobility as well as speed. Through mobile itself you can scan bar-code of products and complete customer billing hassle-free.</p>
                        </Row>
                    </div>
                </section>
                <section className="shape-18 py-2">
                    <div className="container mx-auto ">
                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content shape-9">
                                    <h3 className="section-title">Built natively in the cloud for flexibility and scalability</h3>
                                    <p>Invest in greater stability, security, and scalability to increase competitive momentum and ensure resilience.</p>
                                    <h6><b>Deploy and make it better</b></h6>
                                    <p>Simultaneously deploy software updates and integrations across all properties</p>
                                    <h6><b>Built-in with capacity</b></h6>
                                    <p>Grow the business with built-in capacity for expansion</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={pos2} className="w-100 img-responsive pl-lg-3" alt="point-of-sale" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Designed for multichannel guest experiences</h3>
                                    <p>Provide consistent, high-quality experiences across a spectrum of hardware options and order types</p>
                                    <p>Leverage the innovative OrderNow mobile ordering component and integrations with leading delivery applications</p>
                                     <p>Increase throughput and reduce wait times with customizable and configurable kiosks for on-location, self-service orders</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={pos3} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Comprehensive menu-management visibility and control.</h3>
                                    <p>Administer consistent offerings above-store with advanced enterprise management capabilities</p>
                                    <p>Ensure accuracy and profitability with current and consistent item pricing</p>
                                    <p>Unify operations and standardize menus, combos, items, pricing, and more</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={pos4} className="w-100 img-responsive pl-lg-3" alt="Delivery Outbound" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Stock Management</h3>
                                    <p>Manage stock down to variant, track stock levels, transfer inventory between stores and manage purchase orders.</p>
                                    <h3 className="section-title">Real Time Updates</h3>
                                    <p>Whether you sell in-store, online or on-the-go when a transaction is entered, inventory gets updated everywhere, instantly.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={pos5} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="call-to-action shape-2-1">
                    <div className=" container mx-auto py-5">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Give your Business <br />Real boost Now!</h3>
                                    <p className="section-subtitle">To accomplish great things, we must not only act, but also dream; not only plan, but also believe.</p>
                                    <Button type="primary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={ctaImage} alt="" className="w-70 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>

            </React.Fragment>
        );
    }
}
export default withRouter(PointOfSale);
