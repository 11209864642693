import React from 'react';
import './Header.scss';
import { Link } from 'react-router-dom';
import { Row, Col, BackTop } from 'antd';
import './Footer.scss'
import mainlogowhite from "../assets/images/bizfinway-logo.svg";
import appleStore from "../assets/images/available-on-the-app-store.svg";
import googlePlay from "../assets/images/google-play.svg";

export default class Footer extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="footer">
                    <div className="container mx-auto">
                        <Row gutter={[12, 22]} className="mb-4 pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
                                <img src={mainlogowhite} alt="bizfinway™" width="140" />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 15 }} lg={{ span: 15 }}>
                                <h2 className="text-white text-center">Download Our Apps from</h2>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
                                
                                    <img src={googlePlay} alt="google play" />
                                
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
                                
                                    <img src={appleStore} alt="Apple store" />
                                
                            </Col>
                        </Row>
                        <Row gutter={[12, 22]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                <div className="quick-links">
                                    <h3 className="text-uppercase">Features</h3>
                                    <ul>
                                        <li><Link to="#">Inventory Management</Link></li>
                                        <li><Link to="#">Procurement</Link></li>
                                        <li><Link to="#">Material Management</Link></li>
                                        <li><Link to="#">Financial Accounting</Link></li>
                                        <li><Link to="#">Sales Management</Link></li>
                                        <li><Link to="#">Payment Integrations</Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                <div className="quick-links">
                                    <h3 className="text-uppercase">Reports</h3>
                                    <ul>
                                        <li><Link to="#">Sales Reports</Link></li>
                                        <li><Link to="#">Discreet Production</Link></li>
                                        <li><Link to="#">Management Information System</Link></li>
                                        <li><Link to="#">Financial Reports</Link></li>
                                        <li><Link to="#">Grid Reports</Link></li>
                                        <li><Link to="#">Standard Reports</Link></li>
                                    </ul>
                                </div>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                <div className="quick-links">
                                    <h3 className="text-uppercase">Why bizfinway™</h3>
                                    <ul>
                                        <li><Link to="#">Why we are different</Link></li>
                                        <li><Link to="#">Our Technology</Link></li>
                                        <li><Link to="#">Security and Trust</Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                <div className="quick-links">
                                    <h3 className="text-uppercase">Products</h3>
                                    <ul>
                                        <li><Link to="#">CRM</Link></li>
                                        <li><Link to="#">Project Management</Link></li>
                                        <li><Link to="#">Point-of-Sale</Link></li>
                                        <li><Link to="#">Business Calendar</Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                <div className="quick-links">
                                    <h3 className="text-uppercase">About bizfinway™</h3>
                                    <ul>
                                        <li><Link to="/about-us">About us</Link></li>
                                        <li><Link to="#">Careers</Link></li>
                                        <li><Link to="#">Help & Support</Link></li>
                                        <li><Link to="#">Terms & Conditions</Link></li>
                                        <li><Link to="#">Privacy Policy</Link></li>
                                        <li><Link to="#">Copyright Policy</Link></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <div className="bottom-footer pt-3 mt-3">
                            <Row align="center">
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="social-media">
                                        <ul className="mb-0 pl-0">
                                            <li><Link to="#"><i className="flaticon-facebook-3" /></Link></li>
                                            <li><Link to="#"><i className="flaticon-instagram" /></Link></li>
                                            <li><Link to="#"><i className="flaticon-twitter" /></Link></li>
                                            <li><Link to="#"><i className="flaticon-youtube-1" /></Link></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <p className="text-md-center text-lg-right ">© Copyright {(new Date().getFullYear())} bizfinway™, All Rights Reserved.</p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <BackTop>
                    <div className="back-to-top">UP</div>
                </BackTop>
            </React.Fragment>
        );
    }
}