import React from "react";
import { withRouter } from "react-router";
import "./HomeSlider.scss";
import bannerImg from '../../../assets/images/bannerwhy1.png';
import { Row, Col, Button } from "antd";

import {
  ShoppingCartOutlined,
  GiftOutlined,
  ReadOutlined,
  TeamOutlined,
  DollarOutlined,
  DeploymentUnitOutlined,
  BankOutlined,
  ThunderboltOutlined
} from '@ant-design/icons';

class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section>
        <div className="bussiness-slider pb-5">
          <Row className="mx-0 mb-4">
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              {/* <div className="area" >
                <ul className="circles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div > */}
              <div className="banner-content shape-2-1 text-center py-4">
                <h1 style={{ fontSize: '45px',color: '#fff' }} className="py-4">A cloud ERP to run your business easily</h1>
                <h2 style={{ color: '#fff' }} >The customizable application solution.
                </h2>
                <p style={{ color: '#fff' }}>bizfinway™ is a cloud-based Enterprise Resource Planning software (ERP), perfect for small-to-medium-sized businesses.
                </p>
                <div className="checkbox-group">
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input type="checkbox" className="checkbox-input" />
                      <span className="checkbox-tile">
                        <span className="checkbox-icon " style={{ color: '#40a9ff' }}>
                           <ShoppingCartOutlined />
                        </span>
                        <span className="checkbox-label">Sales</span>
                      </span>
                    </label>
                  </div>
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input type="checkbox" className="checkbox-input" />
                      <span className="checkbox-tile">
                        <span className="checkbox-icon" style={{ color: '#a9cc00' }}>
                        <GiftOutlined />
                        </span>
                        <span className="checkbox-label">Inventory</span>
                      </span>
                    </label>
                  </div>
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input type="checkbox" className="checkbox-input" />
                      <span className="checkbox-tile">
                        <span className="checkbox-icon"  style={{ color: '#ff5656' }}>
                        <ReadOutlined />
                        </span>
                        <span className="checkbox-label">Accounting</span>
                      </span>
                    </label>
                  </div>
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input type="checkbox" className="checkbox-input" />
                      <span className="checkbox-tile">
                        <span className="checkbox-icon" style={{ color: 'gray' }}>
                        <TeamOutlined />
                        </span>
                        <span className="checkbox-label">HRMS</span>
                      </span>
                    </label>
                  </div>
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input type="checkbox" className="checkbox-input" />
                      <span className="checkbox-tile">
                        <span className="checkbox-icon" style={{ color: '#00bdb2' }}>
                        <DollarOutlined />
                        </span>
                        <span className="checkbox-label" >Payroll</span>
                      </span>
                    </label>
                  </div>
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input type="checkbox" className="checkbox-input" />
                      <span className="checkbox-tile">
                        <span className="checkbox-icon" style={{ color: '#abcde' }}>
                        <DeploymentUnitOutlined />
                        </span>
                        <span className="checkbox-label">Production</span>
                      </span>
                    </label>
                  </div>
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input type="checkbox" className="checkbox-input" />
                      <span className="checkbox-tile">
                        <span className="checkbox-icon" style={{ color: '#656fba' }}>
                        <BankOutlined />
                        </span>
                        <span className="checkbox-label">Fixed Assets</span>
                      </span>
                    </label>
                  </div>
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input type="checkbox" className="checkbox-input" />
                      <span className="checkbox-tile">
                        <span className="checkbox-icon" style={{ color: '#00b507' }}>
                        <ThunderboltOutlined />
                        </span>
                        <span className="checkbox-label" >Quality</span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="text-center shape-12 middle-image">
                <img src={bannerImg} className="img-fluid" alt="home-image-itop" />
              </div>

            </Col>
          </Row>
        </div>
        </section>
      </React.Fragment>
    );
  }
}
export default withRouter(HomeSlider);
