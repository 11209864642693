import React from "react";
import { Row, Col, Button } from "antd";
import "./Home.scss";
import HomeSlider from "./HomeSlider/HomeSlider";
import FeaturesTab from "./FeaturesTab/FeaturesTab";
import EndtoendClouderp from "./EndtoendClouderp/EndtoendClouderp";
import WhychooseBizNest from "../../assets/images/why-choose-biznest.png";
import ctaImage from "../../assets/images/cta-img.png";

export default class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* main slider starts */}
        <HomeSlider />
        {/* main slider ends */}
        
        <FeaturesTab />

        <div className="why-choose-biznest container mx-auto my-3 py-5">
          <Row align="center" className="text-center pb-3">
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <h2 className="section-title mb-4">One end-to-end solution to grow your business with ease</h2>
            </Col>
          </Row>
          <Row align="center" gutter={[10, 10]} className="text-center">
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 7 }}>
              <div className="card mb-3 shape-1">
                <div className="card-details text-left">
                  <h5>Easy-to-Use Interface</h5>
                  <p>An interface with a high rate of user adoption</p>
                </div>
              </div>
              <div className="card mb-3 shape-2">
                <div className="card-details text-left">
                  <h5>Multi Company Data Sharing</h5>
                  <p>This is one of the many key solutions we offer via bizfinway™</p>
                </div>
              </div>
              <div className="card mb-3 shape-3">
                <div className="card-details text-left">
                  <h5>Customized Reporting</h5>
                  <p>Customize reports to dial in on insights you need in the momnent</p>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 10 }}>
              <img src={WhychooseBizNest} alt="Why choose BizNest" className="w-100 img-responsive" />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 7 }}>
              <div className="card mb-3 shape-3">
                <div className="card-details text-left">
                  <h5>Robotic Process Automation</h5>
                  <p>Automate your reccuring process/ transaction flows

                  </p>
                </div>
              </div>
              <div className="card mb-3 shape-4">
                <div className="card-details text-left">
                  <h5>State of the Art Security</h5>
                  <p>Cloud level ERP security, with industry level compliance</p>
                </div>
              </div>
              <div className="card mb-3 shape-6">
                <div className="card-details text-left">
                  <h5>Document Printing Suite</h5>
                  <p>Print reports , sales , purchase documents, etc.</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <EndtoendClouderp />

        {/* <div className="what-we-do container mx-auto  py-5">
          <Row align="center" gutter={[10, 22]} className="my-4 text-left">
            <Col className="pr-lg-4" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <h2>Our ERP Solutions</h2>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
              <Button type="secondary">Leran More..</Button>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Row align="center" gutter={[10, 10]}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="our-works">Material Management</div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="our-works">Converge
                    Integration</div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="our-works">Sales
                    Management</div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="our-works">Financial
                    Account</div>
                </Col>

              </Row>
            </Col>
          </Row>
        </div> */}

        <section className="call-to-action shape-15">
          <div className=" container mx-auto py-5">
              <Row align="center" gutter={[12, 22]}>
                  <Col className="center-contents  shape-21" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <div className="middle-content">
                          <h3 className="section-title mb-2">Give your business a real boost now!</h3>
                          <p className="section-subtitle">To accomplish great things, we must not only act, but also dream; not only plan, but also believe.</p>
                          <Button type="primary" className="text-uppercase">Get Started Now</Button>
                      </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <img src={ctaImage} alt="" className="w-70 mx-auto img-responsive" />
                  </Col>
              </Row>
          </div>
         </section>


      </React.Fragment>
    );
  }
}