import React from "react";
import { withRouter } from "react-router";
import { Row, Col, Button } from "antd";
import "./../LandingPage/Home.scss";
import ctaImage from "../../assets/images/cta-img.png";
import accountingSlider from "../../assets/images/accounting/fi-3.png";
import accountReceivables from "../../assets/images/accounting/account-receivables.png";
import accountPayables from "../../assets/images/accounting/account-payables.png";
import bankCash from "../../assets/images/accounting/cash-and-bank.png";
import generalLedger from "../../assets/images/accounting/general-ledger.png";


class FinancialAccounting extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="sales-slider shape-15  py-5">
                    <div className="container mx-auto py-4">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Financial Accounting System with Integrated Business Intelligence</h3>
                                    <p className="sales-section-subtitle">A robust financial accounting keeps you in control of your business and gives you information that enriches your business decisions backed by real data.</p>
                                    <Button type="secondary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={accountingSlider} alt="" className="w-100 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-11  py-5">
                    <div className="container mx-auto py-5">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-subtitle">Organized and hassle free small and medium business accounting</h2>
                                <p>Keep up with your Financial Information and automate all your financial processes from one hub</p>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-profit-100.png" alt="financial-accounting" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Budgeting and Forecasting</h6>
                                        <p className="text-muted mb-0">Define your company budgets and forecast sales and other transac</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-accounting-100.png" alt="financial-accounting" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Budget and Financial Plannig</h6>
                                        <p className="text-muted mb-0">Define and plan finances accordingly and be stress free by visualizing your budget vs other factors</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-business-management-100.png" alt="financial-accounting" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Setup for Financial Accounting</h6>
                                        <p className="text-muted mb-0">A feature rich customization with various accounting setting for yuor company</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-sweepstakes-100.png" alt="financial-accounting" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Cash Payments and Receipts</h6>
                                        <p className="text-muted mb-0">Automate or manually post transaction of a customer or Vendor and send emails</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-tips-100.png" alt="financial-accounting" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Bank Payments and Receipts</h6>
                                        <p className="text-muted mb-0">When a customer pays automatically generate Receipts and when purchased , generate vendor Receipts.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-journal-100.png" alt="financial-accounting" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Journal Register</h6>
                                        <p className="text-muted mb-0">Recorded cash vouchers, bank vouchers, purchase vouchers, sales vouchers,  purchase return vouchers and sales return vouchers in journal register.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-ledger-100.png" alt="financial-accounting" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Group Ledger & Ledger</h6>
                                        <p className="text-muted mb-0">Track the orders placed by customers and delivery status of sales orders</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-balance-sheet-100.png" alt="financial-accounting" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Trail Balance</h6>
                                        <p className="text-muted mb-0">Compilation of different ledgers debit and credit account and reports of balances</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-balance-sheet-100-2.png" alt="financial-accounting" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Balance Sheet</h6>
                                        <p className="text-muted mb-0">Fully Customizable Balance sheet with drill down reports</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-folder-tree-100.png" alt="financial-accounting" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Document Management</h6>
                                        <p className="text-muted mb-0">Manage all accounting document from customer, vendor receipts to GST Invoies</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-10 py-5">
                    <div className=" container mx-auto ">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-title">Small and medium Business Accounting Cloud Software</h2>
                                <p>Our business accounting cloud solution is an excellent tool to take control of your finances and on demand reports</p>
                            </Col>
                        </Row>

                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-11 py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content shape-9">
                                    <h3 className="section-title">Built to Manage all Account Receivables & Finance Operations</h3>
                                    <p>Visualise and generate all transactions from opening balances to GST Invoices and Miscellaneous Documents </p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={accountReceivables} className="w-100 img-responsive pl-lg-3" alt="Sales Quotation" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Streamline Purchases & Control Payables</h3>
                                    <p>Now its easy to transact between the Accounts and be updated about the pending and return documents of your vendors </p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={accountPayables} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Capture and sort all cash and bank settlements</h3>
                                    <p>If your company is a typical business, you deal with a variety of cash transactions. want to record your cash transactions in a number of different ways depending on the nature of your business.</p>
                                    <p>Sales and Cash Receipt Journals</p>
                                    <p>Daily Cash Sheet</p>
                                    <p>Cash Disbursements</p>
                                    <p>Petty Cash</p>
                                    <p>Bank Reconcilations</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={bankCash} className="w-100 img-responsive pl-lg-3" alt="Delivery Outbound" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Take control of your general ledger accounting on the cloud</h3>
                                    <p>Record, sort, store and summarize your companies transactions without any cumbersome actions</p>
                                    <p>Asset accounts - cash, Inventory , Investments, Equipment and Receivables</p>
                                    <p>Liability Accounts - Payables, Vendor Deposits</p>
                                    <p>Stake Holder Equity Accounts - Common stock, treasury stock</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={generalLedger} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="call-to-action shape-2-1">
                    <div className=" container mx-auto py-5">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Give your Business real boost Now!</h3>
                                    <p className="section-subtitle">To accomplish great things, we must not only act, but also dream; not only plan, but also believe.</p>
                                    <Button type="primary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={ctaImage} alt="call-to-action" className="w-70 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>

            </React.Fragment>
        );
    }
}
export default withRouter(FinancialAccounting);
