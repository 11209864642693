import React from "react";
import { withRouter } from "react-router";
import { Row, Col, Button,Card } from "antd";
import "./../LandingPage/Home.scss";
import "./OpenBanking.scss";
import openBankingShow from "./../../assets/images/banking/open-banking-1.png";
import openBanking2 from "./../../assets/images/banking/open-banking-2.png";
import openBanking3 from "./../../assets/images/banking/itop-busness-visa-card.png";
import openBanking4 from "./../../assets/images/banking/open-banking-4.png";
import openBanking5 from "./../../assets/images/banking/open-banking-5.png";
import openBanking7 from "./../../assets/images/banking/open-banking-7.png";
import ctaImage from "../../assets/images/cta-img.png";

const { Meta } = Card;

class OpenBanking extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="shape-23 py-5">
                    <div className="container shape-14 mx-auto py-2">
                        <Row className="py-4" style={{justifyContent: 'center'}}>
                            <h1 className="section-title-big text-center">Open Bank & Cash Management System <br></br>for Business Growth</h1>
                            <h6 className="mt-3 text-center">From account payables, account receivables to cash & bank reconciliation manage everything,<br></br>
                              from a single online bank account</h6>
                        </Row>
                        <Row className="py-1" style={{justifyContent: 'center'}}>
                           <img src={openBankingShow} alt="Open Banking" className="mx-auto img-open-banking"/>
                        </Row>

                        <Row className="open-banking-keys">
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }}>
                                <Card style={{ width: 300, marginTop: 16 }} className="shape-1">
                                    <Meta
                                        title="In-built"
                                        description="Tightly integrated with all modules, experience the workflow"
                                    />
                                </Card>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }}>
                                <Card style={{ width: 300, marginTop: 16 }} className="shape-2">
                                    <Meta
                                        title="Best"
                                        description="User-friendly and extensive functionality with indyustry standard practises"
                                    />
                                </Card>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }}>
                                <Card style={{ width: 300, marginTop: 16 }} className="shape-3">
                                    <Meta
                                        title="Faster"
                                        description="Make or receive payments in seconds be it sales orders to payroll"
                                    />
                                </Card>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span:6, order: 1 }}>
                                <Card style={{ width: 300, marginTop: 16 }} className="shape-4">
                                    <Meta
                                        title="Flexible"
                                        description="You can configure UPI payments, bank accounts, cards"
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-24 py-2">
                    <div className="why-choose-biznest container mx-auto my-3 py-2">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 24 }} lg={{ span: 24 }}>
                            <h2 className="section-title mb-4">A business account & VISA business card designed for businesses</h2>
                            <p>The Most Powerful bsuiness account built for unique needs of small and medium businesses</p>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[10, 10]} className="text-center">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 7 }}>
                            <div className="card mb-3 shape-1">
                                <div className="card-details text-left">
                                <h5>Debit Card aternative</h5>
                                <p>For spending on business needs</p>
                                </div>
                            </div>
                            <div className="card mb-3 shape-2">
                                <div className="card-details text-left">
                                <h5>Integrated with Expense Management</h5>
                                <p>Track all your expenses of bsuiness in one dashboard</p>
                                </div>
                            </div>
                            <div className="card mb-3 shape-3">
                                <div className="card-details text-left">
                                <h5>Accepted everywhere</h5>
                                <p>VISA cards are accepted almost in every lane</p>
                                </div>
                            </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 10 }}>
                            <img src={openBanking3} alt="open banking" className="w-100 img-responsive" />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 7 }}>
                            <div className="card mb-3 shape-3">
                                <div className="card-details text-left">
                                <h5>Opt-in immediately</h5>
                                <p>Paying virtually and tracking is never easy</p>
                                </div>
                            </div>
                            <div className="card mb-3 shape-4">
                                <div className="card-details text-left">
                                <h5>Receive Physical card </h5>
                                <p>Delivered in a week from application date</p>
                                </div>
                            </div>
                            <div className="card mb-3 shape-6">
                                <div className="card-details text-left">
                                <h5>Manage expenses of teams</h5>
                                <p>With virtual and VISA cards</p>
                                </div>
                            </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-18 py-2">
                    <div className="shape-2-1 container mx-auto ">
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-12 py-5">
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={openBanking2} className="w-100 img-responsive pr-lg-3" alt="Automation" />
                            </Col>
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Better Banking leads to growth</h3>
                                    <h3 className="section-subtitle">Collect Payments</h3>  
                                    <p>Collect advances to invoices with bizfinway™ payments that comes integrated with various payment options</p>
                                    <h3 className="section-subtitle">Send Payouts</h3>  
                                    <p>Simplified way to do payouts to vendors, running payroll, settlements</p>
                                    <h3 className="section-subtitle">Better Banking</h3>  
                                    <p>Connect & manage all your existing current accounts and view bank statements on one dashboard</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section> 
                <section className="shape-14 py-2">
                    <div className="shape-11 container mx-auto py-2">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-subtitle">Everything from Collecting Payments to bulk payouts all in one place</h2>
                                <p>Do everything from payments, to banking, automated accounting & all that’s in between - one Open account that covers it all</p>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-skip-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">vendor payouts</h6>
                                        <p className="text-muted mb-0">Make vendor payouts in single or bulk transfer, on-the-go</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-cash-app-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Accept Payments</h6>
                                        <p className="text-muted mb-0">Send payment links to your customers mobile no. or email id in minutes</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-color-switch-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Multiple Payment Methods</h6>
                                        <p className="text-muted mb-0">Payouts via IMPS, NEFT, RTGS & UPI</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-bill-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">GST Compliant Invoicing</h6>
                                        <p className="text-muted mb-0">GST ready invoices for hassle free payment collection</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-coins-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Get Paid Instantly</h6>
                                        <p className="text-muted mb-0">Send payment links & accept local & international payments instantly</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-split-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">secured Payments</h6>
                                        <p className="text-muted mb-0">Robust security that is PCI DSS Level 1 compliant with 128-bit encryption</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-23 py-2">
                    <div className=" container shape2-1 mx-auto ">
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-11 py-2">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content shape-9">
                                    <h3 className="section-title">Easy account payables & account receivables management</h3>
                                    <p></p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={openBanking4} className="w-100 img-responsive pl-lg-3" alt="open banking"/>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Get an overview of cash-in-hand & cash-in-bank from one place</h3>
                                    <p></p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={openBanking5} className="shape-16 w-100 img-responsive pr-lg-3" alt="open banking" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Integrate payment gateway to collect payments via credit & debit cards, bank transfers, UPI</h3>
                                    <p></p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={openBanking7} className="w-100 img-responsive pl-lg-3" alt="open banking" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Experience smarter & error-free cash reconciliation</h3>
                                    <p></p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src="" className="w-100 img-responsive pr-lg-3" alt="open banking" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Powers growth across businesses of every size and industry.</h3>
                                    <p></p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src="" className="w-100 img-responsive pl-lg-3" alt="open banking" />
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-10 py-2">
                    <div className="what-we-do container mx-auto  py-5">
                            <Row align="center" gutter={[5, 10]} className="text-left">
                                <Col className="pr-lg-4" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <h2>Optimal & Intelligent</h2>
                                <p>Integrated with latest technologies the ERP is designed with great architecture and is a cloud based system</p>
                                <p>All members of our core management team are experts in their respective fields, and have been working together for several years.</p>
                                <p>Our in-house IT and development team allows us to provide technology-enabled solutions that can make processes more efficient and effective</p>
                                <Button type="secondary">Leran More..</Button>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Row align="center" gutter={[5, 5]}>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="text-bold our-works">Delivering Business Value</div>
                                    <p>We will go miles ensuring the software in achieving business goals.</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="our-works">Structured Approach</div>
                                    <p>An integrated approch to fit all divisions and data integrity</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="our-works">Low Investment Cost</div>
                                    <p>Get those investors right into your business with a cost effective IT systems</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="our-works">Adaptability</div>
                                    <p>Since bizfinway™ is flexible and design driven system can be adapted by all</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="our-works">Industry Expertise</div>
                                    <p>Our business analytical team has an experience of 19+ years in ERP Domain</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="our-works">Quality Assured</div>
                                    <p>Thousands of hours testing , functional and non-functional</p>
                                    </Col>
                                </Row>
                                </Col>
                            </Row>
                    </div>
                </section>  
                <section className="bg-success-gradiant py-5">
                    <div className="container mx-auto py-5">
                            <Row align="center" gutter={[5, 10]} className="text-left">
                                <h2 className="text-white section-title">Your success is our goal, lets do it.</h2>
                            </Row>
                    </div>
                </section>  
                <section className="call-to-action shape-2-1">
                    <div className=" container mx-auto py-5">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Give your Business <br />Real boost Now!</h3>
                                    <p className="section-subtitle">To accomplish great things, we must not only act, but also dream; not only plan, but also believe.</p>
                                    <Button type="primary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={ctaImage} alt="" className="w-70 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        )}
}
export default withRouter(OpenBanking);
