import React from "react";
import { withRouter } from "react-router";
import { Row, Col, Button } from "antd";
import "./../LandingPage/Home.scss";
import "./ProcurementInventory.scss";

import ctaImage from "../../assets/images/cta-img.png";
import inventorySlider from "../../assets/images/inventory/purchase-2.svg";
import vendorManagement from "../../assets/images/inventory/vendor-management.png";
import purchaseRequisition from "../../assets/images/inventory/purchase-req.png";
import outboundDelivery from "../../assets/images/inventory/inbound-delivery.png";
import purchaseOrder from "../../assets/images/inventory/purchase-order.png";
import purchaseInvoice from "../../assets/images/inventory/purchase-invoice.png";
import purchaseReturns from "../../assets/images/inventory/purchase-returns.png";
import materialManagement from "../../assets/images/inventory/material-management.png";

import {
    CheckCircleOutlined,
    BellOutlined
} from '@ant-design/icons';
class ProcurementInventory extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="shape-12 py-5">
                    <div className="container shape-21 mx-auto py-4">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Procurement and complete visibility of your warehouses</h3>
                                    <p className="sales-section-subtitle">bizfinway™ provides a flexible and easy-to-use approach to procurement and material management. Empower your company’s supply chain management to function efficiently and cost effectively.</p>
                                    <Button type="secondary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={inventorySlider} alt="" className="w-100 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-22  py-5">
                    <div className="container shape-14 mx-auto py-5">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-subtitle">Effective purchase and inventory management</h2>
                                <p>Control your Inventory more efficiently across multiple plants</p>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-skip-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Procurement Process</h6>
                                        <p className="text-muted mb-0">Procurement of your business materials with approval process flows</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-truck-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3"> Inbound & Sub‐Contracting Process</h6>
                                        <p className="text-muted mb-0">Manage the incoming goods and vendor contracts</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-reporting-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3"> Stock Valuation and Stock Reports</h6>
                                        <p className="text-muted mb-0">Evaluate stock, and check available invebtory in your physical stores or warehouses</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-workers-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Vendor Management</h6>
                                        <p className="text-muted mb-0">Manage your vendors, contract types, bank accounts and addresses</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-barcode-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3"> Bar‐Coding Interface </h6>
                                        <p className="text-muted mb-0">Flexible interface to  manage scanned raw materials, WIP and finished products</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-split-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Inventory Transfers</h6>
                                        <p className="text-muted mb-0">Material can be transferred between various legal/global entities</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-workflow-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Batch Management</h6>
                                        <p className="text-muted mb-0">Get full transaction and expiry date histories for batch and serial-controlled materials</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-transaction-list-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Characteristics</h6>
                                        <p className="text-muted mb-0">Batch Characteristics and Material Characteristics for deep inventory</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/iTop/icons/iTop-tree-structure-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Bin Management System</h6>
                                        <p className="text-muted mb-0">Organize your inventory across all storages with binning and transferring</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-10 py-5">
                    <div className=" container mx-auto ">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-title">A cloud-based inventory management system to efficiently control stock, with real-time reports.</h2>
                                <p></p>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-11 py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content shape-9">
                                    <h3 className="section-title">Manage vendors from bank accounts to GST addresses</h3>
                                    <p>Select vendors, negotiating contracts, controlling costs, reducing vendor-related risks, and ensure service delivery.
                                        Reduce the risk of supply chain disruption to ensure goods and services are delivered on time and to standard.
                                    </p>
                                    <p><CheckCircleOutlined /> Vendor Notifications</p>
                                    <p><BellOutlined /> Realtime bank account Verification</p>
                                    <p><CheckCircleOutlined /> Pay directly to Vendor Bank Account</p>
                                    <p><CheckCircleOutlined /> Vendor Enable/Disable</p>

                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={vendorManagement} className="w-100 img-responsive pl-lg-3" alt="Sales Quotation" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Define and manage all types of materials</h3>
                                    <p>Ensure the required materials are available to meet production schedules. Monitor material planning with logistics, stock levels, materials quality, cost and more.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={materialManagement} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Purchase Requisitions</h3>
                                    <p>As soon as sales order is processed, software automatically prompts the inventory, finance and shipping departments to act accordingly.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={purchaseRequisition} className="w-100 img-responsive pl-lg-3" alt="Delivery Outbound" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Generate purchase orders on approval</h3>
                                    <p>Maintain accurate, detailed records of orders and send the orders only when approved by your purchase authorized teams.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={purchaseOrder} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Track your Inbound Delivery Orders from Vendors</h3>
                                    <p>Manage movement from different vendor shipments in your warehouse(s).</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={outboundDelivery} className="w-100 img-responsive pl-lg-3" alt="Delivery Outbound" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-2-1  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Auto-post purchase invoices to accounting system</h3>
                                    <p>Convert purchase-level documents to invoices, with approval, and maintain vendor credit and account payable</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={purchaseInvoice} className="w-100 img-responsive pr-lg-3" alt="business analytics" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3 className="section-title">Return damaged goods & Cancel Purchase documents</h3>
                                    <p>When you bulk import materials with damaged pieces of stock of on failure of contract terms , cancel the purchase orders and return the material to vendors</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={purchaseReturns} className="w-100 img-responsive pl-lg-3" alt="Delivery Outbound" />
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="call-to-action shape-15">
                    <div className=" container mx-auto py-5">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents  shape-21" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Give your business a real boost now!</h3>
                                    <p className="section-subtitle">To accomplish great things, we must not only act, but also dream; not only plan, but also believe.</p>
                                    <Button type="primary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={ctaImage} alt="" className="w-70 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>

            </React.Fragment>
        );
    }
}
export default withRouter(ProcurementInventory);
