import React from "react";
import { withRouter } from "react-router";
import { Row, Col, Button } from "antd";
import "./../LandingPage/Home.scss";
import ctaImage from "../../assets/images/cta-img.png";
import fixedAssetSlider from "../../assets/images/accounting/fixeda-1.png";

class FixedAssets extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className=" shape-15  py-5">
                    <div className="container mx-auto py-4">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Track and manage asset movements of your enterprise.</h3>
                                    <p className="sales-section-subtitle">Maintenance of accurate, up-to-date and complete fixed asset records is a challenge especially when preparing for an audit.Hence, bizfinway™ integrated a solution for managing assets and respective data to provide you more insight visually</p>
                                    <Button type="secondary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={fixedAssetSlider} alt="" className="w-100 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-9  py-5">
                    <div className="container mx-auto py-5">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-subtitle">Simplified to track assets across multiple plants/branches</h2>
                                <p>Tentire lifecycle of fixed assets can be Managed including acquisition, depreciation, adjustments, and disposal from bizfinway™ Fixed Assets Module</p>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-email-document-100.png" alt="fixed-assets" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Aquisition Of Assets</h6>
                                        <p className="text-muted mb-0">Manage assets aquisition and complete aquisition with data across business years</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-receipt-and-change-100.png" alt="fixed-assets" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Capitalization of Asset</h6>
                                        <p className="text-muted mb-0">Track tangible and intangible assets to capitalize based on its nature of completeness</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-get-cash-100.png" alt="fixed-assets" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Asset JV</h6>
                                        <p className="text-muted mb-0">Manage Joint venture Assets and get your shared value of asset evaluated</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-small-business-100.png" alt="fixed-assets" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Classwise depreciation</h6>
                                        <p className="text-muted mb-0">Multiple methods to support prorates, Fixed and many more</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-supply-chain-100.png" alt="fixed-assets" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Revaluation</h6>
                                        <p className="text-muted mb-0">Evaluate your assets time to time to be in compliances with taxes</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-partially-shipped-100.png" alt="fixed-assets" width="50" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Sales of Asset</h6>
                                        <p className="text-muted mb-0">Sell your assets after valuation and close your assets and be updated all the time</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="endtoend-clouderp  shape-10 py-5">
                    <div className=" container mx-auto ">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-subtitle">Key feature that bizfinway™ offers for Asset Management across all companies</h2>
                            </Col>
                        </Row>

                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-11 py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content shape-9">
                                    <h3>Increase Organization of Asset Data</h3>
                                    <p>A web-based software solution can allow multiple users to access and update asset records in real-time, in one single, comprehensive database. No more multiple, messy spreadsheets.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src="" className="w-100 img-responsive pl-lg-3" alt="Fixed Assets" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-11 py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content shape-9">
                                    <h3>Maintain More Detailed Asset Records</h3>
                                    <p>Leverage user defined fields and customizable options when implementing a new asset management software solution to ensure your organization is equipped to capture all necessary asset data.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src="" className="w-100 img-responsive pl-lg-3" alt="Fixed Assets" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-11 py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content shape-9">
                                    <h3>Save Time Preparing Reports</h3>
                                    <p>easily access asset records or download the information you need with user friendly reporting tools.Solutions which allow you to create customized reports can save even more time and make managing your data even easier.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src="" className="w-100 img-responsive pl-lg-3" alt="Fixed Assets" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-11 py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content shape-9">
                                    <h3>File Reports on Time</h3>
                                    <p>Instantly run reports that meet the needs of your auditors. With a robust reporting tool, this might mean no more having to manipulate spreadsheet data to create reports for your auditors.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src="" className="w-100 img-responsive pl-lg-3" alt="Fixed Assets" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-11 py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content shape-9">
                                    <h3>Automate the Transfer of Assets</h3>
                                    <p>Flexible approval routing and which incorporates your own business rules can help enforce compliance, increase efficiency and eliminate errors and delays when it comes to transferring assets.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src="" className="w-100 img-responsive pl-lg-3" alt="Fixed Assets" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-11 py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content shape-9">
                                    <h3>Keep documents organized</h3>
                                    <p>Upload documents like purchase orders, insurance policies, user guides, and images to have all necessary information in one place.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src="" className="w-100 img-responsive pl-lg-3" alt="Fixed Assets" />
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="call-to-action shape-2-1">
                    <div className=" container mx-auto py-5">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Give your Business <br />Real boost Now!</h3>
                                    <p className="section-subtitle">To accomplish great things, we must not only act, but also dream; not only plan, but also believe.</p>
                                    <Button type="primary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={ctaImage} alt="call-to-action" className="w-70 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(FixedAssets);
