import React from "react";
import { withRouter } from "react-router";
import { Row, Col, Button } from "antd";
import "./../LandingPage/Home.scss";
import "./QualityManagement.scss";

import ctaImage from "../../assets/images/cta-img.png";
import quality1 from "../../assets/images/quality/quality-1.svg";
import quality2 from "../../assets/images/quality/quality-2.svg";
import quality3 from "../../assets/images/quality/quality-3.svg";
import quality4 from "../../assets/images/quality/quality-4.svg";
import quality5 from "../../assets/images/quality/quality-5.svg";
import quality6 from "../../assets/images/quality/quality-6.svg";


import {
    CheckCircleOutlined,
    BellOutlined
} from '@ant-design/icons';
class QualityManagement extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="shape-18 py-5">
                    <div className="container mx-auto">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">A robust Quality Management System(QMS) to monitor and control quality.</h3>
                                    <p className="sales-section-subtitle">Gain trust of your customers with increased predictability using integrated variation and defect reduction tools.Track quality in manufacturing parts with the same integrated database as your manufacturing, accounting, customer management.</p>
                                    <Button type="secondary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={quality2} alt="quality" className="w-100 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-21  py-5">
                    <div className="container mx-auto py-5">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-subtitle">Guide your quality system implementation through bizfinway™</h2>
                                <p>Connect your existing data and process for a risk analytical and greater efficiency system.</p>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="text-center pb-3 shape-24">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-skip-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Quality Planning</h6>
                                        <p className="text-muted mb-0">Plan and define the procurements, productiona and characteristics of products</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-truck-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Inspection</h6>
                                        <p className="text-muted mb-0"> Generate standard inspection orders automatically for purchase order, sales order and production order</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="shape-2-1 card border-hover-primary hover-scale">
                                    <div className="card-body">
                                        <div className="text-primary mb-2">
                                            <img src="https://images.impacttopline.com/itop/icons/itop-reporting-100.png" width="60" />
                                        </div>
                                        <h6 className="font-weight-bold mb-3">Quality Control</h6>
                                        <p className="text-muted mb-0">Quality control charts, quality scores for inspection lots, quality notifications</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
               
                <section className="shape-22 py-3">
                    <div className="container shape-14 mx-auto">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-subtitle">A platform to manage production or procurement quality</h2>
                                <p>Let's see how bizfinway™ QMS can benefit organizations from procurement to production process</p>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions py-5">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content shape-9">
                                    <h3 className="section-subtitle">Complete overview of quality-related activities</h3>
                                    <p>Inbuilt or integrable QMS: integrated data such as RMAs, non-conforming inventory, BOMs, and much more.</p>
                                    <p>Inventory & receiving inspections,Reject & scrap tracking and analysis,Document linking and tracking,Complete audit trails,Supplier performance rating system,Lot and serial number tracking and traceability</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={quality1} className="w-100 img-responsive pl-lg-3" alt="Sales Quotation" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-subtitle">Cost of Quality & Statistical Process Control</h3>
                                    <p></p>
                                    <h6><b>Control Cost and Manage Risk</b></h6>
                                    <p>Reduce your cost of quality and risk of warranty issues or recalls</p>
                                    <p>bizfinway™ QMS can help to drive tighter cost control by allowing manufacturers to see and eliminate poor quality and high production costs in areas such as rejects, unscheduled machine downtime, and returns or reworking.</p>
                                    <p>Categorise, treat and manage your entire risk environment with targeted and effective controls.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={quality5} className="w-100 img-responsive pr-lg-3" alt="quality" />
                            </Col>
                        </Row>
                       
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-1 py-3">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3 className="section-subtitle">Advanced Product Quality Planning</h3>
                                    <h6><b>Powerful intelligence</b></h6>
                                    <p>Centralised information and KPI dashboards give you the insight and intelligence to interrogate your entire QMS</p>
                                    <h6><b>Aligned processes</b></h6>
                                    <p>Construct workflows of any shape and size to handle any kind of quality process</p>

                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={quality4} className="w-100 img-responsive pl-lg-3" alt="quality" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="erp-solutions shape-12  py-5">
                            <Col className="center-contents" xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }}>
                                <div className="middle-content">
                                    <h3 className="section-subtitle">Ensure products consistently meet customer quality requirements</h3>
                                    <p>Control to ensure materials are available as required to meet production schedules.Material planning with logistics, stock levels, materials quality, cost and more.</p>
                                    <h6><b>Corrective Actions</b></h6>
                                    <p>Define, manage, schedule, assign, and track Corrective Action Requests to help ensure compliance and meet customer/supplier requirements.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <img src={quality3} className="w-100 img-responsive pr-lg-3" alt="quality" />
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]} className="shape-17 erp-solutions py-3">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                <div className="middle-content">
                                    <h3 className="section-subtitle">End-to-end analysis of quality information</h3>
                                    <p>bizfinway™ QMS can help to drive tighter cost control by allowing manufacturers to see and eliminate poor quality and high production costs in areas such as rejects, unscheduled machine downtime, and returns or reworking.</p>
                                    <h6><b>Document Control</b></h6>
                                    <p>Define and document systems, policies and procedures with robust change control.</p>
                                    <h6><b>Auditing</b></h6>
                                    <p>Uncover risks and opportunities, pinpoint the root cause and drive continuous improvement with smart, mobile auditing.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={quality6} className="w-100 img-responsive pl-lg-3" alt="Delivery Outbound" />
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="shape-22 py-2" style={{backgroundColor:'#05005f'}}>
                    <div className="container shape-12 mx-auto py-5">
                        <Row align="center" className="text-center pb-3">
                            <h2 className="text-white section-title">Experience our software in action</h2>
                        </Row>
                        <Row align="center" className="text-center pb-3">
                            <p className="text-white">See how our quality management software can change your processes for the better.</p>
                        </Row>
                    </div>
                </section>

                <section className="call-to-action shape-15">
                    <div className=" container mx-auto py-5">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents  shape-21" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Give your business a real boost now!</h3>
                                    <p className="section-subtitle">To accomplish great things, we must not only act, but also dream; not only plan, but also believe.</p>
                                    <Button type="primary" className="text-uppercase">Get Started Now</Button>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={ctaImage} alt="" className="w-70 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>

            </React.Fragment>
        );
    }
}
export default withRouter(QualityManagement);
