import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Row, Button, Col, Input, Tooltip } from "antd";
import mainlogo from "../assets/images/finway-logo.svg";
import ModalBox from "../Components/ModalBox/ModalBox";
import SigninSignup from "../Screens/SigninSignup/SigninSignup";
import { SearchOutlined } from "@ant-design/icons";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";


// features menu 
const FeaturesMenu = (
  <section className="mega-menu shape-15  w-100 py-1">
    <div className="container mx-auto py-1">
      <Row align="center" gutter={16}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <Link to="/sales-and-distribution" className="menu-card">
            <img src="https://images.impacttopline.com/itop/icons/itop-layers-100.png" />
            <span className="menu-card-content">
              <span className="menu-card-title">Sales & Distribution</span>
              <span className="menu-card-description">Multi Company and Plant based system designed to be inline always</span>
            </span>
          </Link>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <Link to="/procurement-inventory" className="menu-card">
            <img src="https://images.impacttopline.com/itop/icons/itop-handcart-100.png" />
            <span className="menu-card-content">
              <span className="menu-card-title">Inventory Management</span>
              <span className="menu-card-description">Sophisticated Warehouse Management for all types of Enterprises</span>
            </span>
          </Link>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <Link to="/financial-accounting" className="menu-card">
            <img src="https://images.impacttopline.com/itop/icons/itop-bank-card-dollar-100.png" />
            <span className="menu-card-content">
              <span className="menu-card-title">Financial Accounting</span>
              <span className="menu-card-description">An automated accounting system with high degree of precision</span>
            </span>
          </Link>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <div className="icon-box mt-3">
            <p className="menu-title"><strong>Project Management</strong></p>
            <p className="menu-description">Track your projects, people, efforts at one place.Designed to fit for all businesses</p>
          </div>
        </Col>
      </Row>
      <Row className="shape-2" align="center" gutter={16}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <Link to="/hr-and-payroll" className="menu-card">
            <img src="https://images.impacttopline.com/itop/icons/itop-active-directory-100.png" />
            <span className="menu-card-content">
              <span className="menu-card-title">HR and Payroll</span>
              <span className="menu-card-description">Manage your employees, and recruitment process and salary</span>
            </span>
          </Link>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <Link to="/fixed-assets" className="menu-card">
            <img src="https://images.impacttopline.com/itop/icons/itop-diversity-100.png" />
            <span className="menu-card-content">
              <span className="menu-card-title">Fixed Assets</span>
              <span className="menu-card-description">Manage your company assets from documents to physical assets</span>
            </span>
          </Link>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <Link to="/open-Banking" className="menu-card">
            <img src="https://images.impacttopline.com/itop/icons/itop-bank-building-100.png" />
            <span className="menu-card-content">
              <span className="menu-card-title">Open Bank and Spending</span>
              <span className="menu-card-description">The best cash management system for your organization</span>
            </span>
          </Link>
        </Col>
        
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <div className="icon-box mt-3">
            <p className="menu-title"><strong>Customer Relationship Management</strong></p>
            <p className="menu-description">A wonderful tool to keep your Marketing Campaigns & Leads in one place</p>
          </div>
        </Col>
      </Row>
      <Row className="shape-3" align="center" gutter={16}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <Link to="/quality-management" className="menu-card">
            <img src="https://images.impacttopline.com/itop/icons/itop-warranty-100.png" />
            <span className="menu-card-content">
              <span className="menu-card-title">Quality Management</span>
              <span className="menu-card-description">A system to certify your inventory and gain trust of customers</span>
            </span>
          </Link>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <Link to="/payment-integration" className="menu-card">
            <img src="https://images.impacttopline.com/itop/icons/itop-wire-100.png" />
            <span className="menu-card-content">
              <span className="menu-card-title">Payment Integrations</span>
              <span className="menu-card-description">Payment Integration of your choice,receive payments through UPI,Cards, Bank and Wallets</span>
            </span>
          </Link>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <Link to="/point-of-sale" className="menu-card">
            <img src="https://images.impacttopline.com/itop/icons/itop-small-business-100.png" />
            <span className="menu-card-content">
              <span className="menu-card-title">Point-of-Sale</span>
              <span className="menu-card-description">A POS system integrated for restaurants and retail markets</span>
            </span>
          </Link>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <div className="icon-box mt-3">
            <p className="menu-title"><strong>Business Calendar</strong></p>
            <p className="menu-description">A calendar to manage meetings, campaigns, polls etc... </p>
          </div>
        </Col>
      </Row>
    </div>
  </section>);
  const ResourcesMenu = (
  <Menu>
    <Menu.Item key="0">
      <Link to="#">
        White Papers
      </Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to="#">
       Case Studies
      </Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="#">
        Client Stories
      </Link>
    </Menu.Item>
  </Menu>
);

export let setHeader = false;
export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      signinsignup: false,
      headerView: false,
      name: "",
    };
  }


  onCancel = () => {
    this.setState({ open: false });
    this.setState({ signinsignup: false });
  };
  render() {
    return (
      <React.Fragment>
        <div className="main-hearder">
          <div className="container mx-auto">
            <div className="top-header">
              <Row className="align-items-center">
                <Col
                  xs={{ span: 24, order: 1 }}
                  sm={{ span: 24, order: 1 }}
                  md={{ span: 4, order: 1 }}
                  lg={{ span: 4, order: 1 }}
                >
                  <div className="left-section">
                    <div className="logo">
                      <Link to="/">
                        <img src={mainlogo} alt="itop" />{" "}
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, order: 2 }}
                  sm={{ span: 24, order: 2 }}
                  md={{ span: 15, order: 2 }}
                  lg={{ span: 15, order: 2 }}
                >
                  <div className="bottom-header">
                    <ul>
                      <li>
                        <Dropdown overlay={FeaturesMenu} arrow>
                          <Link to="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()}>Features <DownOutlined /></Link>
                        </Dropdown>
                      </li>
                      <li>
                        <Link to="why-choose-us">Why bizfinway™</Link>
                      </li>
                      <li>
                        <Link to="pricing">Pricing</Link>
                      </li>
                      <li>
                        <Link to="our-technologies">Our Technologies</Link>
                      </li>
                      {/* <li>
                        <Dropdown overlay={ResourcesMenu} arrow>
                          <Link to="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()}>Resources<DownOutlined /></Link>
                        </Dropdown>
                      </li> */}
                      {/* <li>
                        <Link to="our-company">Company</Link>
                      </li> */}
                    </ul>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, order: 3 }}
                  sm={{ span: 24, order: 3 }}
                  md={{ span: 5, order: 3 }}
                  lg={{ span: 5, order: 3 }}
                  align="right"
                >

                  <div className="signin-signup text-right">
                    {/* <Link to="#" className="my-2 my-sm-0 text-dark  mr-5">Login</Link> */}
                    <Button className="nani-button" onClick={() => this.setState({ signinsignup: true })}>
                      Get Started
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>

            {/* bottom header starts */}

            {/* bottom header ends */}
          </div>
        </div>

        <ModalBox
          content="test"
          visible={!!this.state.signinsignup}
          width={900}
          onCancel={this.onCancel}
          footer={null}
          destroyOnClose
        >
          <SigninSignup />
        </ModalBox>
      </React.Fragment>
    );
  }
}
